import "./bootstrap";
import "../css/app.css";

import { createRoot } from "react-dom/client";
import FormGeneral from "@/Pages/Photo/Partials/FormGeneral.jsx";
import "lazysizes";

console.log("initializing blade js");

if (document.getElementById('activate-form-general')) {
    const el = document.getElementById('activate-form-general');
    createRoot(el).render(<FormGeneral />);
}
